define("discourse/plugins/discourse-subscriptions/discourse/models/user-payment", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const UserPayment = _object.default.extend((_dec = (0, _decorators.default)("amount"), (_obj = {
    amountDollars(amount) {
      return parseFloat(amount / 100).toFixed(2);
    }
  }, (_applyDecoratedDescriptor(_obj, "amountDollars", [_dec], Object.getOwnPropertyDescriptor(_obj, "amountDollars"), _obj)), _obj)));
  UserPayment.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/s/user/payments", {
        method: "get"
      }).then(result => result.map(payment => {
        return UserPayment.create(payment);
      }));
    }
  });
  var _default = _exports.default = UserPayment;
});