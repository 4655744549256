define("discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const AdminCoupon = _object.default.extend((_dec = (0, _decorators.default)("coupon.amount_off", "coupon.percent_off"), (_obj = {
    discount(amount_off, percent_off) {
      if (amount_off) {
        return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
      } else if (percent_off) {
        return `${percent_off}%`;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "discount", [_dec], Object.getOwnPropertyDescriptor(_obj, "discount"), _obj)), _obj)));
  AdminCoupon.reopenClass({
    list() {
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        return result.map(coupon => AdminCoupon.create(coupon));
      });
    },
    save(params) {
      const data = {
        promo: params.promo,
        discount_type: params.discount_type,
        discount: params.discount,
        active: params.active
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "post",
        data
      }).then(coupon => AdminCoupon.create(coupon));
    },
    update(params) {
      const data = {
        id: params.id,
        active: params.active
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "put",
        data
      }).then(coupon => AdminCoupon.create(coupon));
    },
    destroy(params) {
      const data = {
        coupon_id: params.coupon.id
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "delete",
        data
      });
    }
  });
  var _default = _exports.default = AdminCoupon;
});