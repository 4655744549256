define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-coupons", ["exports", "@ember/object", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon"], function (_exports, _object, _route, _adminCoupon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _route.default.extend((_obj = {
    model() {
      return _adminCoupon.default.list();
    },
    reloadModel() {
      this.refresh();
    }
  }, (_applyDecoratedDescriptor(_obj, "reloadModel", [_object.action], Object.getOwnPropertyDescriptor(_obj, "reloadModel"), _obj)), _obj));
});