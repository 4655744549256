define("discourse/plugins/discourse-subscriptions/discourse/models/subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const Subscription = _object.default.extend((_dec = (0, _decorators.default)("status"), (_obj = {
    canceled(status) {
      return status === "canceled";
    },
    save() {
      const data = {
        source: this.source,
        plan: this.plan,
        promo: this.promo,
        cardholder_name: this.cardholderName,
        cardholder_address: this.cardholderAddress
      };
      return (0, _ajax.ajax)("/s/create", {
        method: "post",
        data
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "canceled", [_dec], Object.getOwnPropertyDescriptor(_obj, "canceled"), _obj)), _obj)));
  Subscription.reopenClass({
    show(id) {
      return (0, _ajax.ajax)(`/s/${id}`, {
        method: "get"
      });
    }
  });
  var _default = _exports.default = Subscription;
});