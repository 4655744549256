define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/template", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _service, _template, _ajax, _ajaxError, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    loading: false,
    dialog: (0, _service.inject)(),
    stripeConfigured() {
      return !!this.siteSettings.discourse_subscriptions_public_key;
    },
    campaignEnabled() {
      return this.siteSettings.discourse_subscriptions_campaign_enabled;
    },
    campaignProductSet() {
      return !!this.siteSettings.discourse_subscriptions_campaign_product;
    },
    triggerManualRefresh() {
      (0, _ajax.ajax)(`/s/admin/refresh`, {
        method: "post"
      }).then(() => {
        this.dialog.alert(_I18n.default.t("discourse_subscriptions.campaign.refresh_page"));
      });
    },
    createOneClickCampaign() {
      this.dialog.yesNoConfirm({
        title: _I18n.default.t("discourse_subscriptions.campaign.confirm_creation_title"),
        message: (0, _template.htmlSafe)(_I18n.default.t("discourse_subscriptions.campaign.confirm_creation")),
        didConfirm: () => {
          this.set("loading", true);
          (0, _ajax.ajax)(`/s/admin/create-campaign`, {
            method: "post"
          }).then(() => {
            this.set("loading", false);
            this.dialog.confirm({
              message: _I18n.default.t("discourse_subscriptions.campaign.created"),
              shouldDisplayCancel: false,
              didConfirm: () => this.send("showSettings"),
              didCancel: () => this.send("showSettings")
            });
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "stripeConfigured", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "stripeConfigured"), _obj), _applyDecoratedDescriptor(_obj, "campaignEnabled", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "campaignEnabled"), _obj), _applyDecoratedDescriptor(_obj, "campaignProductSet", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "campaignProductSet"), _obj), _applyDecoratedDescriptor(_obj, "triggerManualRefresh", [_object.action], Object.getOwnPropertyDescriptor(_obj, "triggerManualRefresh"), _obj), _applyDecoratedDescriptor(_obj, "createOneClickCampaign", [_object.action], Object.getOwnPropertyDescriptor(_obj, "createOneClickCampaign"), _obj)), _obj));
});