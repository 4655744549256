define("discourse/plugins/discourse-subscriptions/discourse/components/payment-plan", ["exports", "@ember/component", "discourse-common/utils/decorators", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _decorators, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{action "planClick"}}
    class={{concat-class "btn-discourse-subscriptions-subscribe" selectedClass}}
  >
    <span class="interval">
      {{#if this.recurringPlan}}
        {{i18n
          (concat
            "discourse_subscriptions.plans.interval.adverb."
            this.plan.recurring.interval
          )
        }}
      {{else}}
        {{i18n "discourse_subscriptions.one_time_payment"}}
      {{/if}}
    </span>
  
    <span class="amount">
      {{format-currency this.plan.currency this.plan.amountDollars}}
    </span>
  </DButton>
  */
  {
    "id": "/hdRtDRA",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"btn-discourse-subscriptions-subscribe\",[30,0,[\"selectedClass\"]]],null]]],[[\"@action\"],[[28,[37,2],[[30,0],\"planClick\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[14,0,\"interval\"],[12],[1,\"\\n\"],[41,[30,0,[\"recurringPlan\"]],[[[1,\"      \"],[1,[28,[35,4],[[28,[37,5],[\"discourse_subscriptions.plans.interval.adverb.\",[30,0,[\"plan\",\"recurring\",\"interval\"]]],null]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[\"discourse_subscriptions.one_time_payment\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"amount\"],[12],[1,\"\\n    \"],[1,[28,[35,6],[[30,0,[\"plan\",\"currency\"]],[30,0,[\"plan\",\"amountDollars\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `selectedClass` property path was used in the `discourse/plugins/discourse-subscriptions/discourse/components/payment-plan.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.selectedClass}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-button\",\"concat-class\",\"action\",\"if\",\"i18n\",\"concat\",\"format-currency\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/payment-plan.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  const RECURRING = "recurring";
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_dec = (0, _decorators.default)("selectedPlan"), _dec2 = (0, _decorators.default)("plan.type"), (_obj = {
    tagName: "",
    selectedClass(planId) {
      return planId === this.plan.id ? "btn-primary" : "";
    },
    recurringPlan(type) {
      return type === RECURRING;
    },
    actions: {
      planClick() {
        this.clickPlan(this.plan);
        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "selectedClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedClass"), _obj), _applyDecoratedDescriptor(_obj, "recurringPlan", [_dec2], Object.getOwnPropertyDescriptor(_obj, "recurringPlan"), _obj)), _obj))));
});