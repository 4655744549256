define("discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/lib/get-url", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _getUrl, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const AdminSubscription = _object.default.extend((_dec = (0, _decorators.default)("status"), _dec2 = (0, _decorators.default)("metadata"), _dec3 = (0, _decorators.default)("metadata"), (_obj = {
    canceled(status) {
      return status === "canceled";
    },
    metadataUserExists(metadata) {
      return metadata.user_id && metadata.username;
    },
    subscriptionUserPath(metadata) {
      return (0, _getUrl.default)(`/admin/users/${metadata.user_id}/${metadata.username}`);
    },
    destroy(refund) {
      const data = {
        refund
      };
      return (0, _ajax.ajax)(`/s/admin/subscriptions/${this.id}`, {
        method: "delete",
        data
      }).then(result => AdminSubscription.create(result));
    }
  }, (_applyDecoratedDescriptor(_obj, "canceled", [_dec], Object.getOwnPropertyDescriptor(_obj, "canceled"), _obj), _applyDecoratedDescriptor(_obj, "metadataUserExists", [_dec2], Object.getOwnPropertyDescriptor(_obj, "metadataUserExists"), _obj), _applyDecoratedDescriptor(_obj, "subscriptionUserPath", [_dec3], Object.getOwnPropertyDescriptor(_obj, "subscriptionUserPath"), _obj)), _obj)));
  AdminSubscription.reopenClass({
    find() {
      return (0, _ajax.ajax)("/s/admin/subscriptions", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        result.data = result.data.map(subscription => AdminSubscription.create(subscription));
        return result;
      });
    },
    loadMore(lastRecord) {
      return (0, _ajax.ajax)(`/s/admin/subscriptions?last_record=${lastRecord}`, {
        method: "get"
      }).then(result => {
        result.data = result.data.map(subscription => AdminSubscription.create(subscription));
        return result;
      });
    }
  });
  var _default = _exports.default = AdminSubscription;
});