define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription"], function (_exports, _controller, _object, _service, _discourseI18n, _adminCancelSubscription, _adminSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    modal: (0, _service.inject)(),
    dialog: (0, _service.inject)(),
    loading: false,
    showCancelModal(subscription) {
      this.modal.show(_adminCancelSubscription.default, {
        model: {
          subscription,
          cancelSubscription: this.cancelSubscription
        }
      });
    },
    loadMore() {
      if (!this.loading && this.model.has_more) {
        this.set("loading", true);
        return _adminSubscription.default.loadMore(this.model.last_record).then(result => {
          const updated = this.model.data.concat(result.data);
          this.set("model", result);
          this.set("model.data", updated);
          this.set("loading", false);
        });
      }
    },
    cancelSubscription(model) {
      const subscription = model.subscription;
      const refund = model.refund;
      subscription.set("loading", true);
      subscription.destroy(refund).then(result => {
        subscription.set("status", result.status);
        this.dialog.alert(_discourseI18n.default.t("discourse_subscriptions.admin.canceled"));
      }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n"))).finally(() => {
        subscription.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "showCancelModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showCancelModal"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "cancelSubscription", [_object.action], Object.getOwnPropertyDescriptor(_obj, "cancelSubscription"), _obj)), _obj));
});