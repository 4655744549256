define("discourse/plugins/discourse-subscriptions/discourse/models/plan", ["exports", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const Plan = _object.default.extend((_dec = (0, _decorators.default)("recurring.interval"), _dec2 = (0, _decorators.default)("amountDollars", "currency", "billingInterval"), (_obj = {
    amountDollars: (0, _object.computed)("unit_amount", {
      get() {
        return parseFloat(this.get("unit_amount") / 100).toFixed(2);
      },
      set(key, value) {
        const decimal = parseFloat(value) * 100;
        this.set("unit_amount", decimal);
        return value;
      }
    }),
    billingInterval(interval) {
      return interval || "one-time";
    },
    subscriptionRate(amountDollars, currency, interval) {
      return `${amountDollars} ${currency.toUpperCase()} / ${interval}`;
    }
  }, (_applyDecoratedDescriptor(_obj, "billingInterval", [_dec], Object.getOwnPropertyDescriptor(_obj, "billingInterval"), _obj), _applyDecoratedDescriptor(_obj, "subscriptionRate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "subscriptionRate"), _obj)), _obj)));
  var _default = _exports.default = Plan;
});