define("discourse/plugins/discourse-subscriptions/discourse/controllers/user-billing-subscriptions-card", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  /* global Stripe */
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    dialog: (0, _service.inject)(),
    loading: false,
    saved: false,
    init() {
      this._super(...arguments);
      this.set("stripe", Stripe(this.siteSettings.discourse_subscriptions_public_key));
      const elements = this.get("stripe").elements();
      this.set("cardElement", elements.create("card", {
        hidePostalCode: true
      }));
    },
    async updatePaymentMethod() {
      this.set("loading", true);
      this.set("saved", false);
      const paymentMethodObject = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.cardElement
      });
      if (paymentMethodObject.error) {
        this.dialog.alert(paymentMethodObject.error?.message || _I18n.default.t("generic_error"));
        this.set("loading", false);
        return;
      }
      const paymentMethod = paymentMethodObject.paymentMethod.id;
      try {
        await (0, _ajax.ajax)(`/s/user/subscriptions/${this.model}`, {
          method: "PUT",
          data: {
            payment_method: paymentMethod
          }
        });
        this.set("saved", true);
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.set("loading", false);
        this.cardElement?.clear();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "updatePaymentMethod", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updatePaymentMethod"), _obj)), _obj));
});