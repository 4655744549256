define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-products-index", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "I18n", "discourse/plugins/discourse-subscriptions/discourse/models/admin-product"], function (_exports, _object, _route, _service, _I18n, _adminProduct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _route.default.extend((_obj = {
    dialog: (0, _service.inject)(),
    model() {
      return _adminProduct.default.findAll();
    },
    destroyProduct(product) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_subscriptions.admin.products.operations.destroy.confirm"),
        didConfirm: () => {
          return product.destroy().then(() => {
            this.controllerFor("adminPluginsDiscourseSubscriptionsProductsIndex").get("model").removeObject(product);
          }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n")));
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "destroyProduct", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyProduct"), _obj)), _obj));
});